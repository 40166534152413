import { User as IUserRoom, Room } from "store/room";
import { IAppSetting } from "entity/app-setting";
import Card from "components/scrumpoker/card";
import ExitButton from "components/button/exit-button";
import Result from "components/scrumpoker/result";
import User from "components/scrumpoker/user";
import {
  Board,
  ButtonContainer,
  CardContainer,
  Container,
  NewButton,
  ShowButton,
  UsersContainer,
  RoomContainer,
  TopBarContainer,
} from "./style";

export interface Props {
  appSetting: IAppSetting;
  room: Room;
  member: IUserRoom[];
  onExit: () => void;
  onPickCard: (value: string) => void;
  onSaveEstimate: () => void;
  onShowEstimate: () => void;
  onResetEstimate: () => void;
  getValueColor: (userValue: string, sorted: IUserRoom[]) => string;
}

export default function ScrumPoker({
  appSetting,
  room,
  member,
  onPickCard,
  onShowEstimate,
  onResetEstimate,
  onExit,
  getValueColor,
}: Props) {
  return (
    <>
      <TopBarContainer>
        <RoomContainer> Room No. {room.no}</RoomContainer>
        <ExitButton onClick={onExit} />
      </TopBarContainer>

      <Container rgbmode={appSetting.app_setting.rgb_mode}>
        <Board>
          <CardContainer>
            {room.card.map((value) => (
              <Card
                key={`pokercard_${value}`}
                value={value}
                onClick={onPickCard}
              />
            ))}
          </CardContainer>
          <ButtonContainer>
            <NewButton onClick={onResetEstimate}>New</NewButton>
            <ShowButton onClick={onShowEstimate}>
              {room.show ? "Close" : "Show"}
            </ShowButton>
          </ButtonContainer>
          <UsersContainer>
            {member ? (
              member.map((u) => (
                <User
                  key={u.name}
                  username={u.name}
                  show={room.show}
                  voted={u.isVote}
                  value={u.value}
                  color={getValueColor(u.value, member)}
                />
              ))
            ) : (
              <></>
            )}
          </UsersContainer>
        </Board>
      </Container>
    </>
  );
}
