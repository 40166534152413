import { Card as CardValue, CardContainer } from "./style";

interface Props {
  value: string;
  onClick: (v: string) => void;
}

export default function Card({ onClick, value }: Props) {
  function handler(e: React.MouseEvent<HTMLDivElement>) {
    onClick(value);
  }

  return (
    <CardContainer onClick={handler}>
      <CardValue>{value}</CardValue>
    </CardContainer>
  );
}
