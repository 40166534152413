import tw from "tailwind-styled-components"

export const Container = tw.div<{rgbmode:boolean}>`
    p-1 
    max-w-[750px] 
    shadow-lg 
    border 
    dark:border-none 
    bg-slate-100 
    dark:bg-transparent
    rounded-lg 
    ${props => props.rgbmode ? " background-rgb" : ""}
`

export const Board = tw.div`
    rounded-lg  
    text-center 
    w-full
    h-full 
    dark:bg-gray-700 
    p-8 
    flex 
    flex-col 
    gap-8 
    bg-slate-100 
`

export const TopBar = tw.div `
    flex 
    justify-between 
    sm:justify-start
`

export const Input = tw.input`
    rounded-full 
    pl-4 
    mr-2
`

export const SaveEstimateButton = tw.button`
    rounded-full 
    bg-purple-700 
    hover:bg-purple-900  
    py-2 
    px-4 
    text-white
`

export const CardContainer = tw.div`
    flex 
    flex-wrap 
    sm:gap-4 
    gap-6
    justify-around
`

export const UsersContainer = tw.div`
    flex 
    flex-col 
    gap-4
`

export const ButtonContainer = tw.div`
    flex 
    justify-between
    align-middle
    items-center
    mt-2
`

export const NewButton = tw.button`
    rounded-full 
    bg-blue-700 
    hover:bg-blue-900  
    py-2 
    px-4 
    text-white
    min-w-[80px]
    max-h-[45px]
`

export const ShowButton = tw.button`
    rounded-full 
    bg-green-700 
    hover:bg-green-900  
    py-2 
    px-4 
    text-white
    min-w-[80px]
    max-h-[45px]
`
export const TopBarContainer = tw.div`
    flex 
    justify-between 
    items-center
`

export const RoomContainer = tw.h1`
    text-2xl 
    font-semibold 
    dark:text-white 
    mb-4 ml-1
`

