import tw from "tailwind-styled-components"

export const CardContainer = tw.div` 
    border-rgb-hover 
    w-20 
    h-20 
    flex 
    justify-center 
    items-center 
    text-center 
    bg-white 
    shadow-lg 
    p-1  
    rounded-lg 
    cursor-pointer 
    font-medium 
    text-2xl
`

export const Card = tw.div`
    flex 
    justify-center 
    items-center   
    w-20 
    h-20
    bg-slate-300
    text-black  
    dark:bg-gray-600 
    dark:text-white 
    absolute 
    z-10 
    rounded-md
`