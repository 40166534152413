import { Container, ResultNotVote, ResultShow, ResultVoted, Username } from "./style";

interface Props {
    onClick?: () => void,
    username: React.ReactNode,
    show: boolean,
    voted: boolean,
    value: any,
    color:string
}

export default function User({
    username,
    show,
    voted,
    value,
    color,
    onClick = () => { }
}: Props) {

    function handler(e: React.MouseEvent<HTMLDivElement>) {
        onClick()
    }


    if (show && voted) {
        return (
            <Container>
                <Username>{username}</Username>
                <ResultShow style={{backgroundColor:color}}>{value}</ResultShow>
            </Container>
        )
    }


    if (!show && voted) {
        return (
            <Container>
                <Username>{username}</Username>
                <ResultVoted></ResultVoted>
            </Container>
        )
    }

    return (
        <Container>
            <Username>{username}</Username>
            <ResultNotVote></ResultNotVote>
        </Container>
    )
}