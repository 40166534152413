import ExitIcon from "../../icon/exit";
import { Button } from "./style";

interface Props {
    onClick?:(e:React.MouseEvent<HTMLButtonElement>) => void 
}

export default function ExitButton({onClick = (e:any) => {}}:Props) {

    function handler (e:React.MouseEvent<HTMLButtonElement>) {
        onClick(e)
    }

    return (
        <Button onClick={handler}>
            <ExitIcon /> Exit
        </Button>
    )
}