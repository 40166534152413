import { User } from "../store/user"

export function getUserLocalStorage(): User {
    let user = localStorage.getItem('user')
    if (user === null) {
        const defaultUser: User = {
            id:"",
            name:"",
            roomPassword:"",
            roomID:"",
            roomNo:""
        }
        localStorage.setItem('user', JSON.stringify(defaultUser))
        return defaultUser
    }
    return JSON.parse(user)
}

export function deleteUserLocalStorage() {
    localStorage.removeItem("user");
}