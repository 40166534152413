import { useRef, MouseEvent, useState, Fragment, useEffect } from "react"
import { useRecoilState } from "recoil"
import { settingState } from "../../store/setting"
import SettingButton from "../button/setting-button"

export default function SettingModal() {
    const [appSetting, setAppSetting] = useRecoilState(settingState)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const darkMode = useRef<HTMLInputElement>(null)
    const rgbMode = useRef<HTMLInputElement>(null)


    useEffect(() => {
        const onChangeTheme = () => {
            if (appSetting.app_setting.dark_mode) {
                document.documentElement.classList.add('dark')
              } else {
                document.documentElement.classList.remove('dark')
              }
        }

        onChangeTheme()
    },[appSetting.app_setting.dark_mode])


    function handlerOpenModal(event: MouseEvent<HTMLButtonElement>) {
        setOpenModal(true)
    }

    function onSave(event: MouseEvent<HTMLButtonElement>) {

        console.log(darkMode.current?.checked)
        console.log(rgbMode.current?.checked)

        setAppSetting((current) => ({
            ...current,
            app_setting: {
                ...current.app_setting,
                dark_mode: darkMode.current?.checked ? true : false,
                rgb_mode: rgbMode.current?.checked ? true : false,
            }
        }))

        setOpenModal(false)
    }

    function onClose(event: MouseEvent<HTMLButtonElement>) {
        setOpenModal(false)
    }

    return (
        <Fragment>
            <SettingButton onClick={handlerOpenModal} />
            <div className="bg-white dark:bg-gray-700 dark:text-white flex flex-col w-80 absolute top-5 right-4  rounded py-2 px-4 z-[105] shadow border dark:border-none"
                style={{ display: openModal ? "flex" : "none" }}>

                <div className="flex justify-between items-center">
                    <span className="font-bold text-lg">Setting</span>
                    <span className="font-bold  text-lg cursor-pointer" onClick={onClose}>x</span>
                </div>

                <div className="flex flex-col pt-4 pb-2 gap-3 px-2">
                    <div className="flex justify-between items-center">
                        <span className="font-bold text-sm">Dark Mode</span>

                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                ref={darkMode}
                                type="checkbox"
                                value=""
                                className="sr-only peer"
                                defaultChecked={appSetting.app_setting.dark_mode}
                            />

                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white dark:after:bg-gray-500  after:border-gray-700 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white peer-checked:bg-blue-600 peer-checked:dark:after:bg-white "></div>
                        </label>

                    </div>
                    <div className="flex justify-between">
                        <span className="font-bold text-sm">RGB Mode</span>

                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                ref={rgbMode}
                                type="checkbox"
                                value=""
                                className="sr-only peer"
                                defaultChecked={appSetting.app_setting.rgb_mode}
                            />

                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white dark:after:bg-gray-500  after:border-gray-700 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white peer-checked:bg-blue-600 peer-checked:dark:after:bg-white "></div>
                        </label>

                    </div>
                </div>

                <div className="flex justify-end py-2 gap-2">
                    <button
                        type="button"
                        onClick={onSave}
                        className="py-1 px-4 w-24 rounded-full font-sans font-bold hover:shadow bg-green-500 hover:bg-green-600 text-white">save</button>
                </div>
            </div>
        </Fragment>
    )
}