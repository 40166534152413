import { atom } from "recoil";

export interface User {
    name: string,
    isVote: boolean,
    value: string
}

export interface History {
    title: string,
    value: any
}

export interface Room {
    id:string,
    no:string,
    password:string,
    card: string[],
    users: User[],
    show: boolean,
    history: History[],
    summary: number
}

interface Login {
    name: string,
    room_id: string,
    password: string
}

export const roomState = atom<Room>({key:"room", default:{
    id:"",
    no:"",
    password:"",
    card: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
    users: [],
    show: false,
    summary: 0,
    history: []
}})