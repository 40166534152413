import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil"
import { userState } from "../../store/user"

interface Props {
    children:React.ReactNode,
}

export default function WithAuth({children}:Props) {
    const [user, setUser] = useRecoilState(userState)
    const navigate = useNavigate();

    if (user.id === '') {
        navigate('/')
    }

    return (
        <React.Fragment>{children}</React.Fragment>
    )
}