import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { isClientVersionValid } from "util/setting";
import { deleteUserLocalStorage } from "util/user";
interface Props {
    currentVersion:string,
    children:React.ReactNode,
}

export default function WithValidVersion({children, currentVersion}:Props) {
    const navigate = useNavigate();

    if (!isClientVersionValid(currentVersion)) {
        deleteUserLocalStorage();
        navigate('/')
    }

    return (
        <Fragment>{children}</Fragment>
    )
}