import { User } from "../../store/user";
import {socket} from "./socket";

export function emitPickCard(user:User, value:any) {
    socket.emit("room:pick:card", {
        user: user,
        value: value
    })
}

export function emitSaveEstimate(roomID:string, title: string, value: any) {
    socket.emit('room:save:estimate', {
        roomID: roomID,
        history: {
            title: title,
            value: value
        }
    })
}

export function emitShowEstimate(roomID:string, isShow:boolean) {
    socket.emit("room:show", {
        roomID: roomID,
        show: isShow
    })
}

export function emitResetEstimate(roomID:string) {
    socket.emit("room:reset:estimate", {
        roomID: roomID
    })
}

export function emitRoomLogin(user:User) {
    socket.emit('room:login', user)
}

export function emitRoomExit(roomID:string, user:User) {
    socket.emit('room:exit', {user, roomID})
}

export function emitClientDisconnecting(roomID:string, user:User) {
    socket.emit('room:client:disconnecting', {user, roomID})
}