import { atom } from "recoil";
import { IAppSetting } from "../entity/app-setting";
import { getSettingLocalstorage } from "../util/setting";

const localStorageEffect = (key:string) => ({setSelf, onSet}: any) => {
    setSelf(getSettingLocalstorage());
    onSet((newValue:any) => {
        localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const settingState = atom<IAppSetting>({ 
    key: "app_setting", 
    default: getSettingLocalstorage(),
    effects_UNSTABLE: [
        localStorageEffect('setting')
    ] 
})

