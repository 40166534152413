import { ChangeEvent, MouseEvent, useState } from 'react'
import { useRecoilState } from "recoil";
import { settingState } from "../../store/setting";
import BeakerIcon from "../icon/beaker";
import { emitRoomLogin } from '../withSocket/emit-event';

export default function RoomLoginForm() {
    const [appSetting, setAppSetting] = useRecoilState(settingState)
    const [input, setInput] = useState({
        name: "",
        room_id: "",
        password: ""
    })

    function inputHandler(e: ChangeEvent<HTMLInputElement>) {
        setInput(v => ({ ...v, [e.target.name]: e.target.value }))
    }

    function submit(e: MouseEvent<HTMLButtonElement>) {
        emitRoomLogin({
            id:"",
            name:input.name,
            roomID: `room_${input.room_id}`,
            roomNo: input.room_id,
            roomPassword: input.password
        })
    }


    return (
        <div className="flex justify-center items-center min-h-screen relative px-4">
            <div className={`shadow-lg border dark:border-none rounded-lg min-h-[400px] w-full max-w-[550px] min-w-[350px] sm:min-w-[250px] sm:max-w-[550px]  relative ${appSetting.app_setting.rgb_mode ? "border-rgb" : ""}`}>
                <div className="rounded-lg absolute z-10 text-center w-full h-full dark:bg-gray-700 p-8 flex flex-col items-center justify-center bg-white">
                    <div className="flex items-center justify-center w-full font-sans font-bold text-3xl  dark:text-white "><BeakerIcon /> <span className="ml-2 ">SCRUM POKER</span> </div>
                    <div className="w-80 sm:w-96 h-full pt-6 flex flex-col gap-4 mt-4 justify-center items-center">

                        <input
                            className="w-full h-12 rounded-3xl pl-4"
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={input.name}
                            onChange={inputHandler}

                        />

                        <input
                            className="w-full h-12 rounded-3xl pl-4"
                            type="text"
                            name='room_id'
                            placeholder="Room number"
                            value={input.room_id}
                            onChange={inputHandler}
                        />

                        <input
                            className="w-full h-12 rounded-3xl pl-4"
                            type="password"
                            name='password'
                            placeholder="Room Password"
                            value={input.password}
                            onChange={inputHandler}
                        />

                        <button
                            className="bg-slate-800 hover:bg-slate-600 text-white py-4 px-2 rounded-full font-sans font-bold shadow-lg mt-2 w-[80%]"
                            onClick={submit}
                        >Let's poker !! 🚀
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}