import { atom } from "recoil";
import { getUserLocalStorage } from "../util/user";

const localStorageEffect = (key: string) => ({ setSelf, onSet }: any) => {
    setSelf(getUserLocalStorage());
    onSet((newValue: any) => {
        localStorage.setItem(key, JSON.stringify(newValue));
    });
};

export interface User {
    id:string,
    name: string,
    roomPassword: string,
    roomID: string,
    roomNo: string
}

export const userState = atom<User>({
    key: "user",
    default: getUserLocalStorage(),
    effects_UNSTABLE: [
        localStorageEffect('user')
    ]
})

