import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useRecoilState } from "recoil";
import SettingButton from "../components/button/setting-button";
import RoomLoginForm from "../components/form";
import SettingModal from "../components/modal/setting-modal";
import { settingState } from "../store/setting";

export default function Root() {
    const [appSetting, setAppSetting] = useRecoilState(settingState)

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Scrumpoker - worksmash</title>
            </Helmet>
            <SettingModal/>
            <RoomLoginForm />
        </Fragment>

    )
}