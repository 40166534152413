import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { io } from "socket.io-client";
import { Room, roomState } from "../../store/room";
import { User, userState } from "../../store/user";
import { emitClientDisconnecting, emitRoomLogin } from "./emit-event";
import { deleteUserLocalStorage } from "util/user";

interface Props {
  children: React.ReactNode;
}

interface RoomLogin {
  roomNo: string;
  user: User;
}

interface RoomError {
  error: number;
  message: string;
}

//TODO: change to production or development
export const socket = io("wss://scrum-backend-omat5.ondigitalocean.app") //production
//export const socket = io("ws://localhost:5678"); //development

export default function WithSocket({ children }: Props) {
  const navigate = useNavigate();
  const [room, setRoom] = useRecoilState(roomState);
  const [user, setUser] = useRecoilState(userState);

  function handleConnected(param: any) {
    if (user.id !== "") {
      emitRoomLogin(user);
    }
  }

  function handleRoomLogin(param: RoomLogin) {
    setUser(param.user);
    navigate(`room/${param.roomNo}`);
  }

  function handleRoomUpdate(param: Room) {
    setRoom(param);
  }

  function handleRoomExit(param: any) {
    setUser({
      id: "",
      name: "",
      roomPassword: "",
      roomID: "",
      roomNo: "",
    });
    navigate(`/`);
  }

  function handleError(param: RoomError) {
    console.log(param);
    if (param.error === 400) {
      navigate("/");
    }
  }

  function handleBeforeUnload () {
    emitClientDisconnecting(room.id, user);
  };

  function handleOnOffline () {
    deleteUserLocalStorage();
    alert("You are offline, please check your internet connection and you will be redirected to the home page.");
    navigate("/");
  }

  useEffect(() => {
    socket.on("connect", () => {});
    socket.on("connected", handleConnected);
    socket.on("room:logined", handleRoomLogin);
    socket.on("room:update", handleRoomUpdate);
    socket.on("room:error", handleError);
    socket.on("room:exit", handleRoomExit);
    socket.on("disconnect", () => {
      emitClientDisconnecting(room.id, user);
    });
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("offline", handleOnOffline);
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('offline', handleOnOffline);
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}
