import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useRecoilState } from "recoil";
import { settingState } from "store/setting";

import SettingModal from "components/modal/setting-modal";
import ScrumPoker from "components/scrumpoker";
import HistoryTable from "components/table/history";
import useScrumPoker from "hooks/useScrumPoker";

const Container = tw.div`
    flex 
    justify-center 
    px-2 
    py-2 
    w-full 
    h-full
`;
const FlexColumn = tw.div`
    flex 
    flex-col 
    gap-2 
    mt-4
`;

export default function PokerPage() {
  let { id } = useParams();
  const [appSetting] = useRecoilState(settingState);
  const {
    room,
    member,
    handlePickCard,
    handleSaveEstimate,
    handleShowEstimate,
    handleResetEstimate,
    handleMemberChange,
    handlerExit,
    getValueColor,
  } = useScrumPoker();

  //use for set member in room
  useEffect(() => {
    handleMemberChange(room);
  }, [room.users, room.show]);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>room:{id} scrumpoker - maocode</title>
      </Helmet>
      <SettingModal />
      <Container>
        <FlexColumn>
          <ScrumPoker
            appSetting={appSetting}
            room={room}
            member={member}
            onPickCard={handlePickCard}
            onSaveEstimate={handleSaveEstimate}
            onShowEstimate={handleShowEstimate}
            onResetEstimate={handleResetEstimate}
            onExit={handlerExit}
            getValueColor={getValueColor}
          />
        </FlexColumn>
      </Container>
    </Fragment>
  );
}
