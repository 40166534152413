import { IAppSetting } from "../entity/app-setting";

export function getSettingLocalstorage(): IAppSetting {
  let setting = localStorage.getItem("setting");
  if (setting === null) {
    const defaultSetting: IAppSetting = {
      app_setting: {
        dark_mode: false,
        rgb_mode: false,
      },
      scrum_room: {
        room_id: null,
        password: null,
        username: null,
      },
    };
    localStorage.setItem("setting", JSON.stringify(defaultSetting));
    return defaultSetting;
  }
  return JSON.parse(setting);
}

export function isClientVersionValid(current: string): boolean {
  const clientVersion = window.localStorage.getItem("version");
  if (!clientVersion || clientVersion !== current) {
    window.localStorage.clear();
    window.localStorage.setItem("version", current);
    return false;
  }
  return true;
}
