import "./App.css";
import { Route, Navigate, Routes } from "react-router-dom";
import Index from "pages/index";
import Notfound from "pages/notfound";
import PokerPage from "pages/poker";
import WithSocket from "components/withSocket/socket";
import tw from "tailwind-styled-components";
import WithAuth from "components/withAuth";
import WithValidVersion from "components/withValidVersion";

const Container = tw.div` 
  dark:bg-gray-800 
  bg-slate-200 
  min-h-screen 
  relative
`;

const appVersion = "1.0.3";

export default function App() {
  return (
    <WithSocket>
      <Container>
        <Routes>
          <Route index element={<Index />} />
          <Route path="room">
            <Route index element={<Navigate to="/" replace={true} />} />
            <Route path=":id" element={(
              <WithValidVersion currentVersion={appVersion}>
                <WithAuth>
                  <PokerPage />
                </WithAuth>
              </WithValidVersion>)} />
          </Route>
          <Route path="*" element={<Notfound />} />
        </Routes>
      </Container>
    </WithSocket>
  );
}
