export default function Notfound() {
    return (
        <div className="flex flex-col gap-6 justify-center min-h-screen items-center  dark:text-white text-black" id="error-page">
            <h1 className="font-bold text-4xl">Oops!</h1>
            <p className="text-2xl">Sorry, an unexpected error has occurred.</p>
            <p className="text-2xl">
                <i>Not Found Bro!!</i>
            </p>
        </div>
    )
}