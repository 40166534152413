import tw from "tailwind-styled-components"

export const Button = tw.button`
    rounded-full 
    bg-gray-600 
    hover:bg-gray-700 
    px-4 
    py-2 
    text-white 
    flex 
    gap-1 
    justify-center 
    items-center
`