import SettingIcon from "../../icon/setting";
import { MouseEvent } from "react"
import { settingState } from "../../../store/setting";
import { useRecoilState } from "recoil";

interface IProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export default function SettingButton({ onClick }: IProps) {
    const [appSetting, setAppSetting] = useRecoilState(settingState)
    return (
        <div className="absolute top-6 right-5 z-[100]">
            <div className="relative w-12 h-12 sm:w-14 sm:h-14">
                <div className={`rounded-full w-12 h-12 sm:w-14 sm:h-14  ${appSetting.app_setting.rgb_mode ? "border-rgb-circle-absolute" : ""}`}>
                    <button onClick={onClick} className="rounded-full w-12 h-12 sm:w-14 sm:h-14 bg-gray-600  z-20 absolute flex justify-center items-center hover:text-white hover:bg-gray-900 text-white">
                        <SettingIcon />
                    </button>
                </div>
            </div>
        </div>
    )
}