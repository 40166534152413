import { useState } from "react";
import { useRecoilState } from "recoil";
import * as socket from "components/withSocket/emit-event";
import { User as IUserRoom, Room, roomState } from "store/room";
import { userState } from "store/user";

export default function useScrumPoker() {
  const [room] = useRecoilState(roomState);
  const [user] = useRecoilState(userState);

  const [member, setMember] = useState<IUserRoom[]>([]);
  const [estimateForm, setEstimateForm] = useState("Untitle Estimate");
  const [summary, setSummary] = useState(0);

  function userSortByValue(u: IUserRoom[]): IUserRoom[] {
    let user = [...u];
    for (var i = 0; i < user.length; i++) {
      for (var j = 0; j < user.length - i - 1; j++) {
        let curr = parseFloat(user[j].value);
        let next = parseFloat(user[j + 1].value);

        if (isNaN(curr)) curr = 0;
        if (isNaN(next)) next = 0;

        if (curr > next) {
          let temp = user[j];
          user[j] = user[j + 1];
          user[j + 1] = temp;
        }
      }
    }
    return user.reverse();
  }

  function getValueColor(value: string, sorted: IUserRoom[]) {
    if (sorted.length > 0) {
      const points = sorted.map(u => {
        const v = parseFloat(u.value)
        return isNaN(v) ? 0 : v
      });
      const max = Math.max(...points);
      const min = Math.min(...points);
      const parsed = parseFloat(value);
      const userValue = isNaN(parsed) ? 0 : parsed;

      if (userValue >= max) {
        return "#FF4301";
      } else if (userValue <= min) {
        return "#FF8484";
      }
    }
    return "#89C9B8";
  }

  function handleEstimateTitle(e: any) {
    setEstimateForm(e.target.value);
  }

  function handlePickCard(value: string) {
    socket.emitPickCard(user, value);
  }

  function handleSaveEstimate() {
    if (room.summary === 0) return;
    socket.emitSaveEstimate(room.id, estimateForm, summary);
  }

  function handleShowEstimate() {
    socket.emitShowEstimate(room.id, !room.show);
  }

  function handleResetEstimate() {
    socket.emitResetEstimate(room.id);
  }

  function handleResultChange(value: number) {
    setSummary(value);
  }

  function handlerExit() {
    socket.emitRoomExit(room.id, user);
  }

  function handleMemberChange(room: Room) {
    if (room.show) {
      if (room.users.length > 0) {
        setMember(userSortByValue(room.users));
      }
    } else {
      setMember(room.users);
    }
  }

  return {
    room,
    user,
    member,
    setMember,
    estimateForm,
    summary,
    handleEstimateTitle,
    handlePickCard,
    handleSaveEstimate,
    handleShowEstimate,
    handleResetEstimate,
    handleResultChange,
    handleMemberChange,
    handlerExit,
    userSortByValue,
    getValueColor,
  };
}
