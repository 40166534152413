import tw from "tailwind-styled-components"

export const Container = tw.div` 
    flex 
    justify-between 
    items-center 
    bg-slate-200 
    dark:bg-gray-600 
    py-2 
    rounded-full 
    shadow-lg 
    pl-6 
    pr-2
`

export const Username = tw.div`
    font-bold 
    text-base 
    dark:text-white
`
export const ResultVoted = tw.div`
    rounded-full 
    bg-yellow-500
    w-12 
    h-12 
    flex 
    justify-center 
    items-center 
    font-semibold
    text-xl
`

export const ResultShow = tw.div`
    rounded-full 
    w-12 
    h-12 
    flex 
    justify-center 
    items-center 
    font-semibold
    text-xl
`

export const ResultNotVote = tw.div`
    rounded-full 
    bg-transparent
    w-12 
    h-12 
    flex 
    justify-center 
    items-center 
    font-semibold
    text-xl
`